<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            type="search"
            codeGroupCd="SAS_GIVE_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="giveTypeCd"
            label="지급구분"
            v-model="searchParam.giveTypeCd"
          ></c-select>
        </div>
        <div class="col-12">
          <c-datepicker
            :range="true"
            name="period"
            label="신청/지급기간"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          ></c-datepicker>
        </div>
        <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-dept
            type="search"
            :plantCd="searchParam.plantCd"
            :isFirstValue="false"
            label="신청부서"
            name="requestDeptCd"
            v-model="searchParam.requestDeptCd"
          />
        </div> -->
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-field
            :plantCd="searchParam.plantCd"
            label="신청자"
            name="requestUserId"
            v-model="searchParam.requestUserId"
          />
        </div>
        <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-dept
            type="search"
            :plantCd="searchParam.plantCd"
            :isFirstValue="false"
            label="지급부서"
            name="giveDeptCd"
            v-model="searchParam.giveDeptCd"
          />
        </div> -->
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-field
            :plantCd="searchParam.plantCd"
            label="불출담당자"
            name="giveUserId"
            v-model="searchParam.giveUserId"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            type="search"
            codeGroupCd="SAS_STATUS_CD"
            itemText="codeName"
            itemValue="code"
            name="statusCd"
            label="진행상태"
            v-model="searchParam.statusCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-field
            :plantCd="searchParam.plantCd"
            label="수령인"
            name="recipientId"
            v-model="searchParam.recipientId"
          />
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
        <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
                <q-card-section class="grid-card-etc sg">
                  <span :class="['text-grid-etc', getColorStatus(props.row.statusCd)]">
                    {{ props.row.statusName }}
                  </span>
                  <span :class="['text-grid-etc', getColorEndFlag(props.row.recipientSign)]">
                    {{ (props.row.recipientSign!=null) ? '서명' : '미서명' }} 
                  </span>
                  <span class="txt-box-grid text-gray-box">
                    {{ props.row.giveTypeCd=='SGT0000005' ? '무신청지급' : '신청지급' }}
                  </span>
                  <div class="text-grid-title q-mt-sm q-mb-xs mt-3">{{ props.row.giveItems }}</div>
                  <div class="text-grid-etc" v-show="props.row.giveTypeCd=='SGT0000001'">
                    <span class="grey" v-show="props.row.giveTypeCd=='SGT0000001'">신청 : </span>{{ (props.row.requestDeptName?props.row.requestDeptName+' | ':'')+(props.row.requestUserName?props.row.requestUserName+' | ':'')+(props.row.requestDate?props.row.requestDate:'') }}
                  </div>
                  <div class="text-grid-etc">
                    <span class="grey">지급 : </span>{{ (props.row.giveDeptName?props.row.giveDeptName+' | ':'')+(props.row.givUserName?props.row.givUserName+' | ':'')+(props.row.giveDate?props.row.giveDate:'') }} 
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-12" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-gear-request',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'giveItems',
            field: 'giveItems',
            label: '신청품목',
            style: 'width:260px',
            align: 'left',
            type: 'custom',
            sortable: true,
          },
          {
            name: 'requestDate',
            field: 'requestDate',
            label: '신청일',
            style: 'width:120px',
            align: 'center',
            sortable: true,
            type: 'custom',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        requestDeptCd: '',
        giveDeptCd: '',
        giveTypeCd: null,
        startYmd: '',
        endYmd: '',
        requestUserId: null,
        giveUserId: null,
        statusCd: null,
        recipientId: '',
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.sas.request.list.url;
      this.getUrl = selectConfig.sop.sas.give.get.url;
      this.searchParam.recipientId = this.$store.getters.user.userId;
      if (this.$route.query.requestGiveInfoId) {this.getUserDetail();}
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data.filter(item => item.statusCd !== 'SSC0000001');
      },);
    },
    getUserDetail() {
      this.$http.url = this.$format(this.getUrl, this.$route.query.requestGiveInfoId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data) {
          this.linkClick({requestGiveInfoId: this.$route.query.requestGiveInfoId})
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '수령인이 아닙니다.',
            type: 'warning', // success / info / warning / error
          });
        }
      })
    },
    linkClick(row) {
      this.popupOptions.title = '보호구 수령 확인'; 
      this.popupOptions.param = {
        requestGiveInfoId: row.requestGiveInfoId ? row.requestGiveInfoId : '',
      };
      this.popupOptions.target = () => import(`${'./safetyGearSign.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.width = '80%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'SSC0000005': // 신청완료
          cls = 'txt-box-grid text-orange-box';
          break;
        case 'SSC0000010': // 지급중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'SSC0000015': // 지급완료
          cls = 'txt-box-grid text-positive-box';
          break;
      }
      return cls;
    },
    getColorEndFlag(cd) {
      var cls = 'txt-box-grid text-red-box';
      if(cd!=null&&cd.length>0) cls = 'txt-box-grid text-gray-box';
      return cls;
    },
  },
};
</script>
<style lang="sass">
.sg 
  div
    display: block !important
.grey 
  color: #c6c6c6
</style>
